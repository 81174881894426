import * as React from 'react';
import strings from '../../../strings/strings.json';
import { Course } from '../../../models/Course';
import { BodySmall, ButtonRowRight } from 'components/Elements';
import useNeveForm from 'components/forms/NeveForm';
import useModifyRequest from 'hooks/useModifyRequest';
import { logError } from 'lib/debug-helpers';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { ErrorMessage, FormCardWithGaps } from 'components/forms/FormComponents';
import TextField from 'components/forms/TextField';
import SaveNotifier from 'components/notifiers/SaveNotifier';
import { requiredFieldErrorMessage } from 'shared/error-messages';
import InformationIcon from 'assets/icons/indicators/InformationIcon';
import { validateSmallerThanValue } from 'lib/custom-form-validation';
import ErrorIcon from 'assets/icons/indicators/ErrorIcon';
import { validateThreeCharacterLimitDurationRequired } from 'lib/custom-form-validation';
import styled from 'styled-components';
import { breakpoints, sizes } from 'theme';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import useFormBlocker from 'components/forms/useFormBlocker';

type NumberOfLearnersFormInput = {
    learners: {
        minimumLearners: number;
        maximumLearners: number;
    };
};

export function NumberOfLearnersForm(): JSX.Element {
    const context = React.useContext(EditCourseContext);

    const { modifyData: updateCourse, loading } = useModifyRequest(`courses/${context.course?.id}`, 'PUT');
    const [saved, setSaved] = React.useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setError,
        clearErrors,
    } = useNeveForm<NumberOfLearnersFormInput>({
        learners: {
            minimumLearners: context.course?.minimumLearners,
            maximumLearners: context.course?.maximumLearners,
        },
    });

    const [formDirty, setFormDirty] = React.useState<boolean>(false);

    useFormBlocker(formDirty, strings.courseEditorPage.unsavedChangesPrompt);

    const minimumLearners = watch('learners.minimumLearners');
    const maximumLearners = watch('learners.maximumLearners');

    React.useEffect(() => {
        const minDirty = minimumLearners != context.course?.minimumLearners; // eslint-disable-line eqeqeq
        const maxDirty = maximumLearners != context.course?.maximumLearners; // eslint-disable-line eqeqeq

        setFormDirty(minDirty || maxDirty);

        if (!minimumLearners || !maximumLearners) return;

        const validationResponse = validateSmallerThanValue(
            minimumLearners,
            maximumLearners,
            strings.createCoursePage.minimumLearnersInputLabel,
            strings.createCoursePage.maximumLearnersInputLabel,
        );

        if (typeof validationResponse === 'string') {
            setError('learners', { type: 'minAboveMax', message: validationResponse });
        } else {
            clearErrors('learners');
        }
    }, [minimumLearners, maximumLearners]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (saved) {
            setFormDirty(false);
        }
    }, [saved]);

    async function onSubmit(formData: NumberOfLearnersFormInput) {
        setSaved(false);

        const newCourse = {
            ...context.course,
            minimumLearners: formData.learners.minimumLearners,
            maximumLearners: formData.learners.maximumLearners,
        };

        const response = await updateCourse(newCourse as Course);

        const { errors } = response;
        if (errors) {
            logError(errors);
            return;
        }

        reset(formData);
        setSaved(true);
        await context.refetchCourse();
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InputsContainer>
                <TextField
                    fieldName="minimumLearners"
                    maxLength={3}
                    labelText={strings.createCoursePage.minimumLearnersInputLabel}
                    inputAria={strings.createCoursePage.minimumLearnersInputAria}
                    inputProps={register('learners.minimumLearners', {
                        validate: {
                            validateThreeCharacterLimitDurationRequired,
                        },
                        required: {
                            value: true,
                            message: requiredFieldErrorMessage(strings.createCoursePage.minimumLearnersInputLabel),
                        },
                    })}
                    errorMessage={errors.learners?.minimumLearners?.message || errors.learners?.message}
                    hideErrorMessage
                    inputType="number"
                    width="8rem"
                    required
                />
                <TextField
                    fieldName="maximumLearners"
                    maxLength={3}
                    labelText={strings.createCoursePage.maximumLearnersInputLabel}
                    inputAria={strings.createCoursePage.maximumLearnersInputAria}
                    inputProps={register('learners.maximumLearners', {
                        validate: {
                            validateThreeCharacterLimitDurationRequired,
                        },
                        required: {
                            value: true,
                            message: requiredFieldErrorMessage(strings.createCoursePage.maximumLearnersInputLabel),
                        },
                    })}
                    errorMessage={errors.learners?.maximumLearners?.message || errors.learners?.message}
                    hideErrorMessage
                    inputType="number"
                    width="8rem"
                    required
                />
                <LearnersMinMaxEditInfo>
                    <InformationIconContainer>
                        <InformationIcon />
                    </InformationIconContainer>
                    <BodySmall>{strings.createCoursePage.canEditLaterMaxAndMinLearnersNote}</BodySmall>
                </LearnersMinMaxEditInfo>
            </InputsContainer>

            {(errors.learners?.minimumLearners || errors.learners?.maximumLearners || errors.learners) && (
                <ErrorsContainer>
                    {errors.learners && errors.learners.minimumLearners && (
                        <ErrorRow>
                            <ErrorIcon />
                            <ErrorMessage>{errors.learners.minimumLearners.message}</ErrorMessage>
                        </ErrorRow>
                    )}
                    {errors.learners && errors.learners.maximumLearners && (
                        <ErrorRow>
                            <ErrorIcon />
                            <ErrorMessage>{errors.learners.maximumLearners.message}</ErrorMessage>
                        </ErrorRow>
                    )}
                    {errors.learners && errors.learners.message && (
                        <ErrorRow>
                            <ErrorIcon />
                            <ErrorMessage>{errors.learners.message}</ErrorMessage>
                        </ErrorRow>
                    )}
                </ErrorsContainer>
            )}

            <ButtonRowRight>
                <PrimaryButton
                    type="submit"
                    title={strings.courseDetailsForm.submitButton}
                    aria={strings.courseDetailsForm.submitButtonAria}
                    disabled={loading}
                />
            </ButtonRowRight>
            {saved && !formDirty && <SaveNotifier />}
        </Form>
    );
}

const Form = styled(FormCardWithGaps)`
    width: 100%;
`;

const InformationIconContainer = styled.div`
    display: flex;
    width: ${sizes.spacingRg};
`;

const LearnersMinMaxEditInfo = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
    margin-top: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.md}) {
        margin-top: unset;
    }
`;

const InputsContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: ${sizes.spacingLg};
    flex-wrap: wrap;

    @media (max-width: ${breakpoints.sm}) {
    }
`;

const ErrorsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ErrorRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${sizes.spacingSm};
    align-items: center;
`;
