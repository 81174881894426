import { BodyLarge, Headline5, TertiaryButton } from 'components/Elements';
import strings from 'strings/strings.json';
import React from 'react';
import { animated, useSpring } from '@react-spring/web';
import styled from 'styled-components';
import { theme, sizes } from 'theme';

type ExpandableTextContainerProps = {
    headline: string;
    content: string | JSX.Element;
    closedHeightInPx: number;
};

export default function ExpandableContentComponent({
    headline,
    content,
    closedHeightInPx,
}: ExpandableTextContainerProps): JSX.Element {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [isExpandable, setIsExpandable] = React.useState(false);

    const containerRef = React.useRef<HTMLDivElement>(null);

    const { height } = useSpring({
        height: isExpanded ? `${containerRef.current?.scrollHeight}px` : `${closedHeightInPx}px`,
    });

    React.useEffect(() => {
        if (containerRef.current) {
            const isContentLongerThanClosedHeight = containerRef.current.scrollHeight > closedHeightInPx;
            setIsExpandable(isContentLongerThanClosedHeight);
        }
    }, [closedHeightInPx]);

    const contentAsElement: JSX.Element =
        typeof content === 'string' ? <DescriptionText>{content}</DescriptionText> : <>{content}</>;

    return (
        <Container>
            <Headline5>{headline}</Headline5>
            <ExpandableContent
                ref={containerRef}
                fadeheight={isExpanded}
                fadecontent={isExpandable}
                style={{
                    height: isExpandable ? height : 'auto',
                    overflow: 'hidden',
                }}
            >
                {contentAsElement}
            </ExpandableContent>
            {isExpandable && (
                <div>
                    <ShowMoreLessButton
                        onClick={() => {
                            setIsExpanded(!isExpanded);
                        }}
                    >
                        {isExpanded
                            ? strings.learnerCoursePage.expandableButtons.showLess
                            : strings.learnerCoursePage.expandableButtons.showMore}
                    </ShowMoreLessButton>
                </div>
            )}
        </Container>
    );
}

const ExpandableContent = styled(animated.div)<{ fadeheight: boolean; fadecontent: boolean }>`
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 100;
        height: ${({ fadeheight }) => {
            return fadeheight ? 0 : '100%';
        }};
        background-image: ${({ fadecontent }) => {
            return fadecontent ? 'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))' : 'none';
        }};
    }
`;

const ShowMoreLessButton = styled(TertiaryButton)`
    color: ${theme.primaryBrandColour};
    padding: ${sizes.spacingSm};
`;

const DescriptionText = styled(BodyLarge)`
    white-space: pre-wrap;
    overflow-wrap: break-word;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;
