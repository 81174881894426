import strings from '../../strings/strings.json';
import { sizes, theme } from 'theme';
import { Course } from 'models/Course';
import ModuleAccordion from 'pages/edit-course/components/ModuleAccordion';
import MainSection from 'components/sections/MainSection';
import AccessibilityNotifier from '../notifiers/AccessibilityNotifier';
import styled from 'styled-components';
import { Headline3 } from '../Elements';
import { useNavigate } from 'react-router-dom';
import AddIcon from '../../assets/icons/controls/AddIcon';
import NumberOfLearnersSection from './NumberOfLearnersSection';

type CourseOverviewProps = {
    course: Course;
};

export default function CourseOverview({ course }: CourseOverviewProps): JSX.Element {
    const navigate = useNavigate();

    return (
        <MainSection>
            <Container>
                {!course.published && !course.description && (
                    <AccessibilityNotifier
                        accessibilityGuideKey={'noCourseDescription'}
                        fixButtonFunction={() => navigate(`/trainer/course/${course.id}/title-and-description`)}
                        fixButtonName={strings.reviewCoursePage.addCourseDescriptionButtonTitle}
                        fixButtonAria={strings.reviewCoursePage.addCourseDescriptionButtonAria}
                        fixButtonIcon={<AddIcon />}
                    />
                )}
                <NumberOfLearnersContainer>
                    <Headline3>{strings.numberOfLearnersSection.headline}</Headline3>
                    <NumberOfLearnersSection
                        minimumLearners={course.minimumLearners}
                        maximumLearners={course.maximumLearners}
                    />
                </NumberOfLearnersContainer>

                <Headline3>{strings.reviewCoursePage.learningObjectivesHeader}</Headline3>
                {!course.published && course.learningObjectives.length === 0 ? (
                    <AccessibilityNotifier
                        accessibilityGuideKey={'noLearningObjectives'}
                        fixButtonFunction={() => navigate(`/trainer/course/${course.id}/learning-objectives`)}
                        fixButtonName={strings.reviewCoursePage.addLearningObjectivesButtonTitle}
                        fixButtonAria={strings.reviewCoursePage.addLearningObjectivesButtonAria}
                        fixButtonIcon={<AddIcon />}
                    />
                ) : (
                    <ModuleAccordionContainer>
                        {course.learningObjectives.map((learningObjective) => (
                            <ActivityCardContainer key={learningObjective.id}>
                                {learningObjective.description}
                            </ActivityCardContainer>
                        ))}
                    </ModuleAccordionContainer>
                )}

                <Headline3>{strings.reviewCoursePage.modulesHeader}</Headline3>
                <ModuleAccordionContainer>
                    {course.modules
                        .sort((moduleOne, moduleTwo) => {
                            return moduleOne.order < moduleTwo.order ? -1 : 1;
                        })
                        .map((module) => (
                            <ModuleAccordion key={module.id} module={module} />
                        ))}
                </ModuleAccordionContainer>
            </Container>
        </MainSection>
    );
}

const NumberOfLearnersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const Container = styled.main`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    padding-top: ${sizes.spacingLg};
`;

const ActivityCardContainer = styled.div`
    width: 100%;
    background: ${theme.cardBackground};
    padding: ${sizes.spacingMd};
    border: 0.2px solid #385461;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: ${sizes.spacingSm};
    white-space: pre-wrap;
`;

const ModuleAccordionContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
`;
