import styled from 'styled-components';
import { sizes } from '../../theme';
import CheckboxInput, { TextBlock } from './CheckboxInput';

type CheckboxOption = {
    name: string;
    value: string;
    inputProps: object;
    unfocusable?: boolean;
};

export default function Checkboxes({ options }: { options: CheckboxOption[] }): JSX.Element {
    return (
        <AllOptionsContainer>
            {options &&
                options.map((option, index) => (
                    <OptionContainer key={index}>
                        <CheckboxContainer>
                            <CheckboxInput
                                id={option.value}
                                name={option.name}
                                value={option.value}
                                props={option.inputProps}
                                unfocusable={option.unfocusable}
                                aria={`Check: ${option.value}.`}
                            />
                        </CheckboxContainer>
                        <TextBlock htmlFor={option.value}>{option.name}</TextBlock>
                    </OptionContainer>
                ))}
        </AllOptionsContainer>
    );
}

const AllOptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const OptionContainer = styled.div`
    position: relative;
    max-width: 640px;
    align-items: center;
`;

const CheckboxContainer = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-left: ${sizes.spacingMd};
`;
