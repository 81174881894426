import WarningIcon from 'assets/icons/indicators/WarningIcon';
import { Headline5, BodyRegular } from 'components/Elements';
import { ErrorMessage } from 'components/forms/FormComponents';
import styled from 'styled-components';
import { sizes } from 'theme';
import strings from '../../strings/strings.json';

type AccordionPanelTitleProps = {
    title: string;
    description?: string;
    hasNoActivities?: boolean;
};

export function AccordionPanelHeaderTitle({
    title,
    description,
    hasNoActivities,
}: AccordionPanelTitleProps): JSX.Element {
    return (
        <PanelTitle>
            <Headline5>{title}</Headline5>
            {description && <DescriptionText>{description}</DescriptionText>}
            {hasNoActivities && (
                <Warning>
                    <WarningIcon />
                    <ErrorMessage>{strings.courseContentPage.addActivityToThisModule}</ErrorMessage>
                </Warning>
            )}
        </PanelTitle>
    );
}

const PanelTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
    overflow: hidden;
    text-align: left;
`;

const DescriptionText = styled(BodyRegular)`
    white-space: pre-wrap;
`;

const Warning = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
`;
