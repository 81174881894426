import styled from 'styled-components';
import { breakpoints, FONTSIZE, sizes, theme } from '../../theme';

type PrimaryButtonProps = {
    title: string;
    aria: string;
    onClick?: Function;
    type?: 'button' | 'submit' | 'reset' | undefined;
    icon?: JSX.Element;
    disabled?: boolean;
    fullwidth?: boolean;
    alternateColour?: PrimaryButtonColour;
    compact?: boolean;
    colour?: PrimaryButtonColour;
    iconPositionRight?: boolean;
    unfocusable?: boolean;
    buttonRef?: React.RefObject<HTMLButtonElement>;
};

export enum PrimaryButtonColour {
    DEFAULT,
    DANGER,
}

const PRIMARY_BUTTON_COLOUR_LOOKUP = {
    0: {
        textColour: theme.primaryButtonTextColour,
        borderColour: theme.primaryButtonBorderColour,
        backgroundColour: theme.primaryButtonBackgroundColour,
        hoverBackgroundColour: theme.primaryButtonHoverBackgroundColour,
        focusBackgroundColour: theme.primaryButtonFocusBackgroundColour,
        focusTextColour: theme.primaryButtonFocusTextColour,
    },
    1: {
        textColour: theme.primaryButtonTextColour,
        borderColour: theme.errorColour,
        backgroundColour: theme.errorColour,
        hoverBackgroundColour: theme.errorColour,
        focusBackgroundColour: theme.notificationDangerBackground,
        focusTextColour: theme.errorColour,
    },
};

export default function PrimaryButton({
    title,
    aria,
    onClick,
    type,
    icon,
    disabled,
    fullwidth,
    compact,
    colour = PrimaryButtonColour.DEFAULT,
    iconPositionRight = false,
    unfocusable,
    buttonRef,
}: PrimaryButtonProps): JSX.Element {
    async function handleClick() {
        if (onClick) {
            const result = onClick();

            if (result instanceof Promise) {
                await result;
            }
        }
    }

    return (
        <PrimaryButtonContainer
            ref={buttonRef}
            disabled={disabled}
            $fullwidth={fullwidth}
            aria-label={aria}
            type={type ?? 'button'}
            onClick={() => handleClick()}
            compact={compact}
            $colourType={colour}
            $hasTitle={!!title}
            tabIndex={unfocusable ? -1 : 0}
        >
            {iconPositionRight ? title : icon}
            {iconPositionRight ? icon : title}
        </PrimaryButtonContainer>
    );
}

// Please ensure any alterations made to this styling are copied into PrimaryLinkButton
// Under "PrimaryButton", not "AnchorSpecific"
const PrimaryButtonContainer = styled.button<{
    disabled?: boolean;
    $fullwidth?: boolean;
    compact?: boolean;
    $colourType: PrimaryButtonColour;
    $hasTitle: boolean;
}>`
    @media (max-width: ${breakpoints.sm}) {
        width: ${({ compact, $hasTitle }) => {
            if (compact && $hasTitle) return '80px';
            else if (compact) return '48px';
            else return '100%';
        }};
    }
    font-family: ${theme.fontFamilyMain};
    cursor: pointer;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ compact }) => {
        return compact ? sizes.spacingXs : sizes.spacingSm;
    }};
    padding: ${({ compact }) => {
        return compact ? '0 16px' : '0 32px 3px 32px';
    }};
    text-decoration: none;
    border-radius: 24px;
    font-size: ${FONTSIZE.BodyRegular};
    letter-spacing: 0;
    text-align: center;
    border-width: 1px;
    border: none;
    font-weight: bold;
    box-shadow:
        0 0 10px rgba(0, 0, 0, 0.15),
        4px 4px 8px 0 rgba(0, 0, 0, 0.15);
    color: ${({ disabled, $colourType }) => {
        return disabled ? theme.textSecondaryColour : PRIMARY_BUTTON_COLOUR_LOOKUP[$colourType].textColour;
    }};
    border: ${({ $colourType }) => {
        return PRIMARY_BUTTON_COLOUR_LOOKUP[$colourType].borderColour;
    }};
    background-color: ${({ disabled, $colourType }) => {
        return disabled
            ? theme.primaryButtonDisabledBackgroundColour
            : PRIMARY_BUTTON_COLOUR_LOOKUP[$colourType].backgroundColour;
    }};
    max-width: ${({ $fullwidth }) => {
        return $fullwidth ? '100%' : '480px';
    }};
    width: ${({ $fullwidth }) => {
        return $fullwidth ? '100%' : 'auto';
    }};
    &:hover {
        background-color: ${({ disabled, $colourType }) => {
            return disabled ? null : PRIMARY_BUTTON_COLOUR_LOOKUP[$colourType].hoverBackgroundColour;
        }};
    }
    &:focus {
        background-color: ${({ $colourType }) => {
            return PRIMARY_BUTTON_COLOUR_LOOKUP[$colourType].focusBackgroundColour;
        }};
        border: ${({ $colourType }) => {
            return `1px solid ${PRIMARY_BUTTON_COLOUR_LOOKUP[$colourType].borderColour}`;
        }};
        outline: ${({ $colourType }) => {
            return PRIMARY_BUTTON_COLOUR_LOOKUP[$colourType].borderColour;
        }};
        color: ${({ $colourType }) => {
            return PRIMARY_BUTTON_COLOUR_LOOKUP[$colourType].focusTextColour;
        }};
    }
`;
