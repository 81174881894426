import { IconSize } from 'assets/icons/icon-sizes';
import DurationLabel from 'components/DurationLabel';
import { BodyRegular } from 'components/Elements';
import { getActivityTypeIcon } from 'models/Activity';
import { LearnerActivity } from 'models/LearnerCourse';
import { Resource } from 'models/Resources';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme, sizes, breakpoints } from 'theme';
import strings from '../../../strings/strings.json';
import ExternalLinkIcon from '../../../assets/icons/navigation/ExternalLinkIcon';

const ActivityCardHeight = 120;

type ActivityCardGridProps = {
    scheduledCourseId: string;
    activities: LearnerActivity[];
    urlPrefix: string;
};

export default function ActivityCardGrid({
    scheduledCourseId,
    activities,
    urlPrefix,
}: ActivityCardGridProps): JSX.Element {
    const navigate = useNavigate();

    return (
        <CardGrid>
            {activities.map((activity, index) => (
                <CardButton
                    key={index}
                    onClick={() => {
                        navigate(`${urlPrefix}/${scheduledCourseId}/activity/${activity.id}`);
                    }}
                    aria-label={`${strings.immersiveCourseViewButtons.moduleOverviewClickableActivityAria} ${activity.title}`}
                >
                    <IconContainer>{getActivityTypeIcon(activity, IconSize.XLARGE)}</IconContainer>
                    <CardInfo>
                        <BodyRegular $bold>{activity.title}</BodyRegular>
                        {activity.durationInMins && (
                            <DurationLabel durationInMins={activity.durationInMins} hideTitle />
                        )}
                    </CardInfo>
                </CardButton>
            ))}
        </CardGrid>
    );
}

export function ActivityCardGridSkeleton(): JSX.Element {
    return (
        <CardGrid>
            <Skeleton height={ActivityCardHeight} />
            <Skeleton height={ActivityCardHeight} />
            <Skeleton height={ActivityCardHeight} />
            <Skeleton height={ActivityCardHeight} />
        </CardGrid>
    );
}

type ResourceCardGridProps = {
    resources: Resource[];
};

export function ResourceCardGrid({ resources }: ResourceCardGridProps): JSX.Element {
    return (
        <CardGrid>
            {resources.map((resource, index) => (
                <CardButton
                    key={index}
                    onClick={() => {
                        window.open(resource.url, '_blank');
                    }}
                    aria-label={strings.general.openInNewTab}
                >
                    <IconContainer>
                        <ExternalLinkIcon colour={theme.textColour} size={IconSize.XLARGE} />
                    </IconContainer>
                    <ResourceInfo>
                        <BodyRegular $bold>{resource.title}</BodyRegular>
                        <BodyRegular>Link</BodyRegular>
                    </ResourceInfo>
                </CardButton>
            ))}
        </CardGrid>
    );
}

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    background-color: ${theme.iconBackgroundColour};
    border-radius: ${sizes.borderRadiusLg};
`;

const ResourceInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingXs};
`;

const CardInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const CardButton = styled.button`
    all: unset;
    display: flex;
    gap: ${sizes.spacingMd};
    height: ${ActivityCardHeight}px;
    padding: ${sizes.spacingMd};
    cursor: pointer;

    box-sizing: border-box;
    border: 1px solid ${theme.cardBorder};
    border-radius: 8px;
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
    background: ${theme.cardBackground};

    &:focus {
        background-color: ${theme.secondaryButtonFocusBackgroundColour};
        border: 1px solid ${theme.secondaryButtonBorderColour};
        color: ${theme.primaryButtonFocusTextColour};
    }
    &:hover {
        background-color: ${theme.secondaryButtonHoverBackgroundColour};
    }
`;

const CardGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: ${sizes.spacingMd};
    row-gap: ${sizes.spacingMd};
    margin-top: ${sizes.spacingSm};

    @media (max-width: ${breakpoints.sm}) {
        grid-template-columns: auto;
    }
`;
