import styled from 'styled-components';
import { breakpoints, sizes, theme } from '../../theme';
import PrimaryButton, { PrimaryButtonColour } from '../buttons/PrimaryButton';
import useKeyboardTrap from '../../hooks/useKeyboardTrap';
import { BodyRegular, Headline2 } from '../Elements';
import Notification, { LinkToHelp, NotificationType } from '../notifiers/Notification';
import SecondaryButton from '../buttons/SecondaryButton';
import strings from '../../strings/strings.json';

export type AlertError = {
    title: string;
    description: string;
    linkToHelp?: LinkToHelp;
};

type AlertProps = {
    alertText: string | string[];
    buttonText: string;
    ariaButtonText: string;
    buttonColour?: PrimaryButtonColour;
    onSubmit: (() => Promise<void>) | (() => void);
    closeAlert: Function;
    error?: AlertError;
    title?: string;
};

export default function Alert({
    alertText,
    buttonText,
    ariaButtonText,
    buttonColour = PrimaryButtonColour.DEFAULT,
    onSubmit,
    closeAlert,
    error,
    title,
}: AlertProps): JSX.Element {
    alertText = typeof alertText === 'string' ? [alertText] : alertText;

    return (
        <AlertBackground id="alert">
            <AlertBodyContainer>
                <AlertBodyComponent
                    closeAlert={closeAlert}
                    title={title}
                    alertText={alertText}
                    submitButtonTitle={buttonText}
                    submitButtonAria={ariaButtonText}
                    submitButtonColour={buttonColour}
                    submitButtonOnClick={onSubmit}
                    error={error}
                />
            </AlertBodyContainer>
        </AlertBackground>
    );
}

const AlertBodyContainer = styled.div`
    position: absolute;
    top: 10%;

    @media (max-width: ${breakpoints.sm}) {
        top: 20%;
        margin: 0 ${sizes.spacingMd};
    }
`;

type AlertBodyComponentProps = {
    closeAlert: Function;
    title?: string;
    alertText: string | string[];
    submitButtonTitle: string;
    submitButtonAria: string;
    submitButtonColour?: PrimaryButtonColour;
    submitButtonOnClick: () => void;
    error?: AlertError;
};

export function AlertBodyComponent({
    closeAlert,
    title,
    alertText,
    submitButtonTitle,
    submitButtonAria,
    submitButtonOnClick,
    submitButtonColour,
    error,
}: AlertBodyComponentProps): JSX.Element {
    const { ref } = useKeyboardTrap(() => closeAlert);

    alertText = typeof alertText === 'string' ? [alertText] : alertText;

    return (
        <AlertBody id="alertBody" ref={ref}>
            <Headline2>{title ? title : strings.alert.areYouSureDefaultText}</Headline2>

            {alertText.length >= 1 && (
                <div>
                    {alertText.map((text, index) => (
                        <BodyRegular key={index}>{text}</BodyRegular>
                    ))}
                </div>
            )}

            <ButtonContainer>
                <SecondaryButton
                    type="button"
                    title={strings.alert.backButton.title}
                    aria={strings.alert.backButton.aria}
                    onClick={closeAlert}
                />
                <PrimaryButton
                    type="submit"
                    title={submitButtonTitle}
                    aria={submitButtonAria}
                    onClick={submitButtonOnClick}
                    colour={submitButtonColour}
                />
            </ButtonContainer>
            {error && (
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={error.title}
                    description={error.description}
                    linkToHelp={error.linkToHelp}
                    maxWidth={'25rem'}
                />
            )}
        </AlertBody>
    );
}

const AlertBackground = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    top: 80px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.cardBackground};
    z-index: 10;
    @media (max-width: ${breakpoints.sm}) {
        top: 50px;
    }
`;

const AlertBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    align-items: center;
    text-align: center;
    @media (max-width: ${breakpoints.sm}) {
        gap: ${sizes.spacingMd};
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 2rem;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column-reverse;
        width: 100%;
    }
`;
