import { IconSize } from 'assets/icons/icon-sizes';
import WarningIcon from 'assets/icons/indicators/WarningIcon';
import StepCheckIcon from 'assets/icons/StepCheckIcon';
import React from 'react';
import styled from 'styled-components';
import { sizes, theme } from 'theme';
import { BodyRegular } from '../../../components/Elements';

type StepButtonComponentProps = {
    onClick: () => void;
    icon: React.ElementType;
    title: string;
    active: boolean;
    aria: string;
    completed: boolean;
};

export function StepButtonComponent({
    onClick,
    icon,
    title,
    active,
    aria,
    completed,
}: StepButtonComponentProps): JSX.Element {
    const Icon = icon;

    return (
        <StepButton onClick={onClick} $active={active} aria-label={aria}>
            <IconAndTitle>
                <IconContainer>
                    <Icon colour={theme.textColour} size={IconSize.MEDIUM} />
                </IconContainer>
                <BodyRegular $bold>{title}</BodyRegular>
            </IconAndTitle>
            <IconContainer>{completed ? <StepCheckIcon /> : <WarningIcon />}</IconContainer>
        </StepButton>
    );
}

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    height: ${IconSize.LARGE}px;
    width: ${IconSize.LARGE}px;
`;

const IconAndTitle = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    align-items: center;
`;

const StepButton = styled.button<{ $active: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    cursor: pointer;
    text-align: left;

    text-decoration: ${({ $active }) => {
        return $active ? 'none' : 'underline';
    }};

    background-color: ${({ $active }) => {
        return $active ? theme.cardBackground : theme.courseEditButtonBackgroundColour;
    }};

    border-top: 1px solid ${theme.cardSecondaryBorder};
    border-left: ${({ $active }) => {
        return $active ? `${sizes.spacingMd} solid ${theme.primaryBrandColour}` : 'none';
    }};
    border-right: none;
    border-bottom: none;

    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
    padding: 0 ${sizes.spacingMd};

    &:hover {
        background-color: ${theme.secondaryButtonHoverBackgroundColour};
    }
    &:focus {
        background-color: ${theme.primaryButtonFocusBackgroundColour};
        outline: ${theme.primaryButtonBorderColour};
    }
`;
