import * as React from 'react';
import strings from '../../strings/strings.json';
import styled from 'styled-components';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { breakpoints } from 'theme';
import { useNavigate } from 'react-router-dom';
import { ButtonRowRight } from '../Elements';
import NavButton from 'components/buttons/NavButton';
import Spinner from '../Spinner';

type StepperButtonRowProps = {
    submitButtonTitle: string;
    submitButtonAria: string;
    buttonsDisabled?: boolean;
    previousButtonFunction?: Function;
    submitButtonFunction?: Function;
    submitting?: boolean;
    showPreviousButton?: boolean;
};

export default function StepperButtonRow({
    submitButtonTitle,
    submitButtonAria,
    buttonsDisabled,
    previousButtonFunction,
    submitButtonFunction,
    submitting,
    showPreviousButton = true,
}: StepperButtonRowProps): JSX.Element {
    const navigate = useNavigate();

    function buttonFunction() {
        if (previousButtonFunction) {
            previousButtonFunction();
            return;
        }
        navigate(-1);
    }

    return (
        <ButtonRow>
            {showPreviousButton && (
                <div>
                    <NavButton
                        title={strings.stepper.backButtonMobileTitle}
                        aria={strings.stepper.backButtonMobileAria}
                        onClick={buttonFunction}
                        back
                    />
                </div>
            )}
            <ButtonRowRight>
                <PrimaryButton
                    type={submitButtonFunction ? 'button' : 'submit'}
                    title={submitButtonTitle}
                    aria={submitButtonAria}
                    disabled={buttonsDisabled}
                    onClick={submitButtonFunction ? () => submitButtonFunction() : undefined}
                    icon={submitting ? <Spinner /> : undefined}
                />
            </ButtonRowRight>
        </ButtonRow>
    );
}

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column-reverse;
        gap: 2rem;
    }
`;
