import { FormLabel } from 'components/forms/FormComponents';
import styled from 'styled-components';
import { FONTSIZE, sizes, theme } from 'theme';

type DropdownFieldProps = {
    id: string;
    labelText: string;
    aria: string;
    options: string[];
    inputProps: Record<string, unknown>;
    required: boolean;
};

export default function DropdownField({
    id,
    labelText,
    aria,
    required,
    options,
    inputProps,
}: DropdownFieldProps): JSX.Element {
    return (
        <DropdownFieldContainer>
            <FormLabel htmlFor={id}>
                {labelText}
                {required && ' *'}
            </FormLabel>
            <Select id={id} aria-label={aria} {...inputProps}>
                {options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </Select>
        </DropdownFieldContainer>
    );
}

const DropdownFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const Select = styled.select`
    width: 100%;
    height: 48px;
    font-family: ${theme.fontFamilyMain};
    font-size: ${FONTSIZE.BodyRegular};
    border-radius: ${sizes.borderRadiusMd};

    &:focus {
        outline: none;
        border-width: 3px;
        border-color: ${theme.inputActiveColour};
    }
`;
