import { Module } from './Module';
import { LearningObjective } from './LearningObjective';
import { Organisation } from './Organisation';

export type Course = {
    id: string;
    title: string;
    description: string;
    minimumLearners: number;
    maximumLearners: number;
    modules: Module[];
    dateCreated: string;
    dateUpdated: string;
    category: string;
    learningObjectives: LearningObjective[];
    published: boolean;
    userId: string;
    organisation: Organisation;
};

export function EveryModuleHasActivity(course: Course): boolean {
    return course.modules.length >= 1 && course.modules.every((m) => m.activities.length >= 1);
}

export function CourseHasAtLeastOneLearningObjective(course: Course): boolean {
    return course.learningObjectives.length >= 1;
}

export function CourseIsReadyToPublish(course: Course): boolean {
    const courseTitleComplete = !!course.title;
    const minMaxLearnersComplete = course.minimumLearners >= 1 && course.maximumLearners >= 1;
    const learningObjectivesComplete = course.learningObjectives.length >= 1;
    const modulesAndActivitiesComplete =
        course.modules.length >= 1 && course.modules.every((m) => m.activities.length >= 1);

    const courseReadyToPublish =
        courseTitleComplete && minMaxLearnersComplete && learningObjectivesComplete && modulesAndActivitiesComplete;

    return courseReadyToPublish;
}
