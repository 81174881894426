import styled from 'styled-components';
import { sizes } from '../../../theme';
import { Activity } from '../../../models/Activity';
import useGetRequest from '../../../hooks/useGetRequest';
import { EditQuestionnaireStepper } from './EditQuestionnaireStepper';
import LiveTrainingUpdateForm from './LiveTrainingUpdateForm';
import { TaskUpdateForm } from './TaskUpdateForm';

type ActivityUpdateComponentProps = {
    activityId: string;
    completeUpdateQuestionnaire: () => void;
};

export default function ActivityUpdateComponent({
    activityId,
    completeUpdateQuestionnaire,
}: ActivityUpdateComponentProps): JSX.Element {
    const { data: selectedActivity, refetchData: refetchActivity } = useGetRequest<Activity>(
        `activities/${activityId}`,
        !activityId,
    );

    if (!selectedActivity) {
        return <></>;
    }

    switch (selectedActivity.type) {
        case 'QUESTIONNAIRE':
            return (
                <EditQuestionnaireStepper
                    activity={selectedActivity}
                    completeUpdateQuestionnaire={completeUpdateQuestionnaire}
                />
            );

        case 'LIVE_TRAINING':
            return <LiveTrainingUpdateForm activity={selectedActivity} />;

        default:
            return <TaskUpdatePageContent activity={selectedActivity} refetchActivity={refetchActivity} />;
    }
}

const TaskUpdatePageContent = styled(TaskUpdateForm)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: ${sizes.spacingMd};
`;
