import { StepperFormProps } from 'components/stepper/stepperTypes';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import useModifyRequest from 'hooks/useModifyRequest';
import useUploadRequest from 'hooks/useUploadRequest';
import { logError } from 'lib/debug-helpers';
import { APIError } from 'lib/_api-helpers';
import { FileDocument } from 'models/FileDocument';
import { CreateTaskWithAudioRequest } from 'models/inputModels/CreateTaskRequest';
import { TaskActivity } from 'models/inputModels/TaskActivity';
import { Resource } from 'models/Resources';
import React from 'react';
import { createGlobalErrorFromAPIError, createErrorMessage } from 'shared/error-messages';
import { ResourcesInput } from '../ResourcesForm';
import strings from '../../../../strings/strings.json';
import { Headline4, Headline5 } from 'components/Elements';
import { ErrorMessage } from 'components/forms/FormComponents';
import ResourcesList from '../ResourcesList';
import { LearningResourcesFormModal } from '../LearningResourcesFormModal';
import ActivityWizardButtonRow from '../ActivityWizardButtonRow';
import { styled } from 'styled-components';
import { sizes } from 'theme';
import { AudioCard } from '../FileCard';
import { AudioSelectionComponent } from '../FileSelectionComponent';

export default function TaskContentFormForAudio(props: StepperFormProps<TaskActivity>): JSX.Element {
    const { complete, newObject, setNewObject } = props;

    const context = React.useContext(EditCourseContext);

    const [errorsState, setErrors] = React.useState<string[]>([]);
    const [audioFromProvider, setAudioFromProvider] = React.useState<FileDocument>();
    const [linkModalOpen, setLinkModalOpen] = React.useState(false);
    const [resources, setResources] = React.useState<ResourcesInput[]>([]);

    const selectAudioButtonRef = React.useRef<HTMLButtonElement>(null);
    const addLinkButtonRef = React.useRef<HTMLButtonElement>(null);
    const mounted = React.useRef(false);

    React.useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            selectAudioButtonRef.current?.focus();
            return;
        }
    }, []);

    React.useEffect(() => {
        if (newObject.audioFile || audioFromProvider) {
            setErrors([]);
        }
    }, [newObject.audioFile, audioFromProvider]);

    React.useEffect(() => {
        const resourcesInputs: ResourcesInput[] = newObject.resources.map((r) => {
            const resource: ResourcesInput = { id: r.id ?? '', title: r.title, url: r.url };
            return resource;
        });

        setResources(resourcesInputs);
    }, []); // eslint-disable-line

    React.useEffect(() => {
        if (resources.length <= 0) {
            return;
        }

        setNewObject({
            ...newObject,
            resources: resources.map((r) => {
                const resource: Resource = { id: r.id ?? '', title: r.title, url: r.url };
                return resource;
            }),
        });
    }, [resources]); //eslint-disable-line

    const { upload: uploadAudio, uploading: uploadingAudio } = useUploadRequest();

    const { modifyData: createTask, loading: creatingTask } = useModifyRequest(`activities/task/audio`, 'POST');

    function updateErrors(apiErrors: APIError[]): void {
        let newErrors: string[] = [];

        apiErrors.forEach((apiError) => {
            const { field, code } = apiError;

            if (field === 'global') {
                newErrors.push(createGlobalErrorFromAPIError(code));
            } else {
                newErrors.push(createErrorMessage(field, code));
            }
        });

        setErrors(newErrors);
    }

    async function submit() {
        let existingAudioId: string | null = null;
        let audioFilePath: string | null = null;
        let audioFileName: string | null = null;

        if (!audioFromLocal && !audioFromProvider) {
            setErrors(['Audio is a required field']);
            return;
        }

        if (audioFromProvider) {
            existingAudioId = audioFromProvider.id;
        } else if (audioFromLocal) {
            const uploadResponse = await uploadAudio(audioFromLocal);

            if (uploadResponse.errors && uploadResponse.errors.length > 0) {
                logError(uploadResponse.errors);
                updateErrors(uploadResponse.errors);
                return;
            }

            if (!uploadResponse.value) {
                logError('Get presigned URL failed');
                updateErrors([{ field: 'global', code: 'get presigned URL failed' }]);
                return;
            }

            audioFilePath = uploadResponse.value.s3Key;
            audioFileName = uploadResponse.value.filename;
        }

        const request: CreateTaskWithAudioRequest = {
            title: newObject.title,
            durationInMins: newObject.durationInMins,
            moduleId: newObject.moduleId,
            resources: resources,
            existingAudioId: existingAudioId,
            audioFilename: audioFileName,
            audioFilepath: audioFilePath,
        };

        const response = await createTask(request);
        const { errors } = response;

        if (errors) {
            logError(errors);
            updateErrors(errors);
            return;
        }

        await context.refetchCourse();
        complete && complete();
    }

    let uploadDate: Date = audioFromProvider ? audioFromProvider.dateUpdated : new Date();

    const audioFromLocal = newObject.audioFile;

    let audioName: string;
    if (audioFromLocal) audioName = audioFromLocal.name;
    else if (audioFromProvider) audioName = audioFromProvider.filename;
    else audioName = strings.fileUpload.browseFiles;

    const setAudioFromLocal = (newFile: File | null | undefined) => {
        setNewObject({
            ...newObject,
            audioFile: newFile ?? null,
        });
        setAudioFromProvider(undefined);
    };

    const setAudioFromProviderFunc = (audio: FileDocument) => {
        setNewObject({
            ...newObject,
            audioFile: null,
        });
        setAudioFromProvider(audio);
    };

    const removeAudio = () => {
        setNewObject({
            ...newObject,
            audioFile: null,
        });
        setAudioFromProvider(undefined);
        selectAudioButtonRef.current?.focus();
    };

    function addResource(newResource: ResourcesInput) {
        setResources([...resources, newResource]);
        addLinkButtonRef.current?.focus();
    }

    const deleteResource = (id: string | null, title: string, url: string, _: boolean) => {
        setResources(resources.filter((resource) => !(resource.title === title && resource.url === url)));
        addLinkButtonRef.current?.focus();
    };

    const closeLinksModal = () => {
        setLinkModalOpen(false);
        addLinkButtonRef.current?.focus();
    };

    const audioSelected = audioFromLocal || audioFromProvider;

    return (
        <Container>
            <Headline4>{strings.taskContentForm.buildMethod.audio}</Headline4>
            <AudioSection>
                {audioSelected && (
                    <AudioCard
                        fileName={audioName}
                        uploadDate={uploadDate}
                        removeFile={removeAudio}
                        disabled={uploadingAudio || creatingTask}
                    />
                )}
                <AudioSelectionComponent
                    setFileFromLocal={setAudioFromLocal}
                    setFileFromProvider={setAudioFromProviderFunc}
                    setFileFromLocalMetaData={() => {}}
                    disableButton={uploadingAudio || creatingTask}
                    selectFileButtonRef={selectAudioButtonRef}
                />
            </AudioSection>
            {errorsState.length > 0 &&
                errorsState.map((error, index) => (
                    <ErrorMessage id={`error-message-${index}`} key={index}>
                        {error}
                    </ErrorMessage>
                ))}
            <LinksSection>
                <Headline5>{strings.resourcesList.title}</Headline5>
                <ResourcesList
                    resources={resources}
                    deleteResource={deleteResource}
                    toggleModalOpen={() => setLinkModalOpen(!linkModalOpen)}
                    disabled={uploadingAudio || creatingTask}
                    addLinkButtonRef={addLinkButtonRef}
                />
            </LinksSection>
            {linkModalOpen && <LearningResourcesFormModal closeModal={closeLinksModal} addResource={addResource} />}
            <ActivityWizardButtonRow
                submitButtonTitle={strings.taskContentForm.submitButton}
                submitButtonAria={strings.taskContentForm.submitButtonAria}
                submitButtonFunction={submit}
                submitting={creatingTask}
                showPreviousButton={false}
            />
        </Container>
    );
}

const LinksSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const AudioSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;
