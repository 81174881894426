import TextField from '../../../components/forms/TextField';
import strings from '../../../strings/strings.json';
import {
    validateSmallerThanValue,
    validateThreeCharacterLimitDurationRequired,
} from '../../../lib/custom-form-validation';
import { requiredFieldErrorMessage } from '../../../shared/error-messages';
import { breakpoints, sizes } from '../../../theme';
import InformationIcon from '../../../assets/icons/indicators/InformationIcon';
import { BodySmall } from '../../../components/Elements';
import * as React from 'react';
import ErrorIcon from '../../../assets/icons/indicators/ErrorIcon';
import { ErrorMessage } from '../../../components/forms/FormComponents';
import styled from 'styled-components';
import { FieldErrors, UseFormClearErrors, UseFormRegister, UseFormSetError, UseFormWatch } from 'react-hook-form';
import useWindowWidth from '../../../hooks/useWindowWidth';

export interface NumberOfLearnersSectionInput {
    learners: {
        minimumLearners: number;
        maximumLearners: number;
    };
}

export default function NumberOfLearnersSection({
    errors,
    register,
    watch,
    setError,
    clearErrors,
}: {
    errors: FieldErrors<NumberOfLearnersSectionInput>;
    register: UseFormRegister<any>;
    watch: UseFormWatch<any>;
    setError: UseFormSetError<NumberOfLearnersSectionInput>;
    clearErrors: UseFormClearErrors<NumberOfLearnersSectionInput>;
}): JSX.Element {
    const { currentWindowWidth } = useWindowWidth();
    const minimumLearners = watch('learners.minimumLearners');
    const maximumLearners = watch('learners.maximumLearners');

    React.useEffect(() => {
        if (!minimumLearners || !maximumLearners) return;

        const validationResponse = validateSmallerThanValue(
            minimumLearners,
            maximumLearners,
            strings.createCoursePage.minimumLearnersInputLabel,
            strings.createCoursePage.maximumLearnersInputLabel,
        );

        if (typeof validationResponse === 'string') {
            setError('learners', { type: 'minAboveMax', message: validationResponse });
        } else {
            clearErrors('learners');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minimumLearners, maximumLearners]);

    const showErrors = () => (
        <ErrorsContainer>
            {errors.learners && errors.learners.minimumLearners && (
                <ErrorRow>
                    <ErrorIcon />
                    <ErrorMessage>{errors.learners.minimumLearners.message}</ErrorMessage>
                </ErrorRow>
            )}
            {errors.learners && errors.learners.maximumLearners && (
                <ErrorRow>
                    <ErrorIcon />
                    <ErrorMessage>{errors.learners.maximumLearners.message}</ErrorMessage>
                </ErrorRow>
            )}
            {errors.learners && errors.learners.message && (
                <ErrorRow>
                    <ErrorIcon />
                    <ErrorMessage>{errors.learners.message}</ErrorMessage>
                </ErrorRow>
            )}
        </ErrorsContainer>
    );

    return (
        <div>
            <LearnerNumbersContainer>
                <InputsContainer>
                    <div>
                        <TextField
                            fieldName="minimumLearners"
                            maxLength={3}
                            labelText={strings.createCoursePage.minimumLearnersInputLabel}
                            inputAria={strings.createCoursePage.minimumLearnersInputAria}
                            inputProps={register('learners.minimumLearners', {
                                validate: {
                                    validateThreeCharacterLimitDurationRequired,
                                },
                                required: {
                                    value: true,
                                    message: requiredFieldErrorMessage(
                                        strings.createCoursePage.minimumLearnersInputLabel,
                                    ),
                                },
                            })}
                            errorMessage={errors.learners?.minimumLearners?.message || errors.learners?.message}
                            hideErrorMessage
                            inputType="number"
                            width="8rem"
                            required
                        />
                    </div>
                    <div>
                        <TextField
                            fieldName="maximumLearners"
                            maxLength={3}
                            labelText={strings.createCoursePage.maximumLearnersInputLabel}
                            inputAria={strings.createCoursePage.maximumLearnersInputAria}
                            inputProps={register('learners.maximumLearners', {
                                validate: {
                                    validateThreeCharacterLimitDurationRequired,
                                },
                                required: {
                                    value: true,
                                    message: requiredFieldErrorMessage(
                                        strings.createCoursePage.maximumLearnersInputLabel,
                                    ),
                                },
                            })}
                            errorMessage={errors.learners?.maximumLearners?.message || errors.learners?.message}
                            hideErrorMessage
                            inputType="number"
                            width="8rem"
                            flavourText={
                                <>
                                    {currentWindowWidth >= parseInt(breakpoints.sm) && (
                                        <LearnersMinMaxEditInfo>
                                            <InformationIcon />
                                            <BodySmall>
                                                {strings.createCoursePage.canEditLaterMaxAndMinLearnersNote}
                                            </BodySmall>
                                        </LearnersMinMaxEditInfo>
                                    )}
                                </>
                            }
                            required
                        />
                    </div>
                </InputsContainer>
                {currentWindowWidth < parseInt(breakpoints.sm) && (
                    <>
                        {errors.learners?.minimumLearners || errors.learners?.maximumLearners ? (
                            showErrors()
                        ) : (
                            <LearnersMinMaxEditInfo>
                                <InformationIcon />
                                <BodySmall>{strings.createCoursePage.canEditLaterMaxAndMinLearnersNote}</BodySmall>
                            </LearnersMinMaxEditInfo>
                        )}
                    </>
                )}
            </LearnerNumbersContainer>
            {(errors.learners?.minimumLearners || errors.learners?.maximumLearners || errors.learners) &&
            currentWindowWidth >= parseInt(breakpoints.sm) ? (
                showErrors()
            ) : (
                <></>
            )}
        </div>
    );
}

const InputsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingLg};

    @media (max-width: ${breakpoints.sm}) {
        gap: ${sizes.spacingMd};
    }
`;

const LearnersMinMaxEditInfo = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
`;

const LearnerNumbersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @media (max-width: ${breakpoints.sm}) {
        align-items: flex-start;
        flex-direction: column;
    }
`;

const ErrorRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${sizes.spacingSm};
    align-items: center;
`;

const ErrorsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
