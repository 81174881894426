import * as React from 'react';
import styled from 'styled-components';
import { breakpoints, sizes } from '../../../theme';
import { LearnerLearningObjective } from '../../../models/LearnerCourse';
import LearningObjective from '../../../components/learningObjectives/LearningObjective';

export default function LearningObjectivesList({
    learningObjectives,
}: {
    learningObjectives: LearnerLearningObjective[];
}): JSX.Element {
    return (
        <LearningObjectivesSection>
            {learningObjectives.map((x) => (
                <LearningObjective key={x.id} description={x.description} />
            ))}
        </LearningObjectivesSection>
    );
}

const LearningObjectivesSection = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: ${sizes.spacingLg};
    column-gap: ${sizes.spacingMd};
    width: 100%;
    white-space: pre-wrap;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
    }
`;
