import { Resource } from '../Resources';

export type BuildTaskMethod = 'Text & image' | 'Video' | 'Audio';

export type TaskActivity = {
    title: string;
    content: string;
    durationInMins: number | null;
    resources: Resource[];
    videoFile: File | null;
    imageFile: File | null;
    audioFile: File | null;
    moduleId: string;
    buildMethod: BuildTaskMethod;
};

export function createEmptyTaskActivity(moduleId: string) {
    return {
        title: '',
        content: '',
        durationInMins: null,
        resources: [],
        videoFile: null,
        imageFile: null,
        audioFile: null,
        moduleId: moduleId,
        buildMethod: 'Text & image' as BuildTaskMethod,
    };
}
