import React from 'react';
import styled from 'styled-components';
import { breakpoints, FONTSIZE, sizes, theme } from '../../theme';

type SecondaryButtonProps = {
    title: string;
    aria: string;
    onClick?: Function;
    onBlur?: Function;
    type?: 'button' | 'submit' | 'reset' | undefined;
    icon?: JSX.Element;
    disabled?: boolean;
    fullwidth?: boolean;
    alternateColour?: SecondaryButtonColour;
    compact?: boolean;
    buttonRef?: React.RefObject<HTMLButtonElement>;
    autoFocus?: boolean;
};

export enum SecondaryButtonColour {
    DEFAULT,
    ALTERNATE,
    DANGER,
}

const SECONDARY_BUTTON_COLOUR_LOOKUP = {
    0: {
        textColour: theme.secondaryButtonTextColour,
        borderColour: theme.secondaryButtonBorderColour,
    },
    1: {
        textColour: theme.secondaryButtonAlternateTextColour,
        borderColour: theme.secondaryButtonAlternateBorderColour,
    },
    2: {
        textColour: theme.errorColour,
        borderColour: theme.errorColour,
    },
};
export default function SecondaryButton({
    title,
    aria,
    onClick,
    type,
    icon,
    disabled,
    fullwidth,
    alternateColour = 0,
    compact,
    onBlur,
    buttonRef,
    autoFocus,
}: SecondaryButtonProps): JSX.Element {
    return (
        <StyledButton
            disabled={disabled}
            $fullwidth={fullwidth}
            aria-label={aria}
            type={type ?? 'button'}
            onClick={() => (onClick ? onClick() : null)}
            onBlur={() => (onBlur ? onBlur() : null)}
            $colourType={alternateColour}
            $compact={compact}
            $hasTitle={!!title}
            ref={buttonRef}
            autoFocus={autoFocus}
        >
            {icon}
            {title}
        </StyledButton>
    );
}

const StyledButton = styled.button<{
    disabled?: boolean;
    $fullwidth?: boolean;
    $compact?: boolean;
    $hasTitle: boolean;
    $colourType: SecondaryButtonColour;
}>`
    @media (max-width: ${breakpoints.sm}) {
        width: ${({ $compact, $hasTitle }) => {
            if ($compact && $hasTitle) return '100px';
            else if ($compact) return '48px';
            else return '100%';
        }};
    }
    font-family: ${theme.fontFamilyMain};
    cursor: pointer;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${sizes.spacingSm};
    padding: ${({ $compact }) => {
        return $compact ? '0 16px' : '0 32px 3px 32px';
    }};
    text-decoration: none;
    border-radius: 24px;
    font-size: ${FONTSIZE.BodyRegular};
    letter-spacing: 0;
    text-align: center;
    font-weight: bold;
    white-space: nowrap;
    box-shadow: none;
    color: ${({ disabled, $colourType }) => {
        return disabled ? theme.textSecondaryColour : SECONDARY_BUTTON_COLOUR_LOOKUP[$colourType].textColour;
    }};
    border: ${({ $colourType }) => {
        return `2px solid ${SECONDARY_BUTTON_COLOUR_LOOKUP[$colourType].borderColour} !important`;
    }};
    background-color: ${({ disabled }) => {
        return disabled ? theme.primaryButtonDisabledBackgroundColour : theme.secondaryButtonBackgroundColour;
    }};
    max-width: ${({ $fullwidth }) => {
        return $fullwidth ? '100%' : '480px';
    }};
    width: ${({ $fullwidth }) => {
        return $fullwidth ? '100%' : 'auto';
    }};
    &:hover {
        background-color: ${({ disabled }) => {
            return disabled ? null : theme.secondaryButtonHoverBackgroundColour;
        }};
    }
    &:focus {
        background-color: ${theme.primaryButtonFocusBackgroundColour};
        border: 1px solid ${theme.primaryButtonBorderColour};
        outline: ${theme.primaryButtonBorderColour};
        color: ${theme.primaryButtonFocusTextColour};
    }
`;
