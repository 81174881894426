import * as React from 'react';
import strings from 'strings/strings.json';
import ScheduleIcon from 'assets/icons/pathway/ScheduleIcon';
import { useNavigate, useParams } from 'react-router-dom';
import useGetRequest from 'hooks/useGetRequest';
import { Course, CourseIsReadyToPublish } from 'models/Course';
import SubpageHeader from '../../components/sections/SubpageHeader';
import SecondaryButton from '../../components/buttons/SecondaryButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import CourseOverview from 'components/courseSchedule/CourseOverview';
import EditIcon from 'assets/icons/controls/EditIcon';
import Skeleton from 'react-loading-skeleton';
import { MainSectionWithGaps } from 'components/sections/MainSection';
import { theme } from 'theme';
import Notification, { LinkToHelp, NotificationType } from 'components/notifiers/Notification';
import Hint from 'components/Hint';

export default function CourseReviewPage(): JSX.Element {
    const params = useParams();
    const { courseId } = params;
    const navigate = useNavigate();

    const { data: course, loading } = useGetRequest<Course>(`courses/${courseId}`, !courseId);

    const goToPublishCourseWarningPage = () => {
        navigate(`/trainer/course/${courseId}/publish-course-warning`);
    };

    const goToEditCourseWarningPage = () => {
        navigate(`/trainer/course/${courseId}/edit-course-warning
        `);
    };

    const goToPreview = () => {
        navigate(`/preview/${courseId}`, {
            state: { previewContextEntryPoint: `/trainer/course/${courseId}/view` },
        });
    };

    const backButtonDetails = React.useMemo(() => {
        return {
            title: course?.published ? `Dashboard` : `${course?.title}`,
            aria: course?.published ? `Dashboard` : `${course?.title}`,
            href: course?.published ? `/dashboard` : `/edit-course/${course?.id}`,
        };
    }, [course]);

    if (loading || !course)
        return (
            <>
                <SubpageHeader
                    title={<Skeleton height={56} count={1} />}
                    subtitle={<Skeleton height={32} count={1} />}
                    backButtonDetails={backButtonDetails}
                    shadedBackground
                    buttons={<></>}
                />
                <MainSectionWithGaps>
                    <Skeleton height={56} count={3} />
                    <Skeleton height={100} count={3} />
                </MainSectionWithGaps>
            </>
        );

    const courseReadyToPublish = CourseIsReadyToPublish(course);

    const linkBackToCourseEditor: LinkToHelp = {
        text: strings.courseEditorPage.returnToCourseLink.text,
        aria: strings.courseEditorPage.returnToCourseLink.aria,
        url: `/edit-course/${course?.id}`,
    };

    const contentBeforeTitle = (
        <>
            {!course.published && (
                <>
                    <Hint hint={strings.reviewCoursePage.hint} />
                    {!courseReadyToPublish && (
                        <Notification
                            notificationType={NotificationType.WARNING}
                            linkToHelp={linkBackToCourseEditor}
                            title={strings.courseEditorPage.buttonValidationError.title}
                            description={strings.courseEditorPage.buttonValidationError.description}
                            maxWidth={'100%'}
                        />
                    )}
                </>
            )}
        </>
    );

    return (
        <>
            <SubpageHeader
                title={course.title}
                subtitle={course.description}
                backButtonDetails={backButtonDetails}
                contentBeforeTitle={contentBeforeTitle}
                shadedBackground
                buttons={
                    <>
                        <SecondaryButton
                            title={strings.courseEditorPage.previewCourseButtonTitle}
                            aria={strings.courseEditorPage.previewCourseButtonAria}
                            onClick={goToPreview}
                        />
                        {course.published && (
                            <SecondaryButton
                                title={strings.coursePage.editButtonTitle}
                                aria={strings.coursePage.editButtonTitleAria}
                                onClick={goToEditCourseWarningPage}
                                icon={<EditIcon />}
                            />
                        )}
                        {course.published ? (
                            <PrimaryButton
                                title={strings.coursePage.scheduleButtonTitle}
                                aria={strings.coursePage.scheduleButtonTitleAria}
                                onClick={() => {
                                    navigate(`/schedule/${course.id}`, {
                                        state: {
                                            returnUrl: `/trainer/scheduled-courses`,
                                            backUrl: `/trainer/course/${courseId}/view`,
                                        },
                                    });
                                }}
                                icon={
                                    <ScheduleIcon
                                        colour={theme.secondaryButtonBackgroundColour}
                                        backgroundColour={theme.primaryBrandColour}
                                    />
                                }
                            />
                        ) : (
                            <PrimaryButton
                                title={strings.courseEditorPage.publishButtonTitle}
                                aria={strings.courseEditorPage.publishButtonTitleAria}
                                onClick={goToPublishCourseWarningPage}
                                disabled={!courseReadyToPublish}
                            />
                        )}
                    </>
                }
            />
            <CourseOverview course={course} />
        </>
    );
}
