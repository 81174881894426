import { Headline4, ModalBackground, ModalCard, TertiaryButton } from 'components/Elements';
import { breakpoints, sizes } from 'theme';
import useKeyboardTrap from '../../hooks/useKeyboardTrap';
import CreateCourseForm from 'pages/trainer/components/CreateCourseForm';
import styled from 'styled-components';
import CloseIcon from 'assets/icons/controls/CloseIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import strings from 'strings/strings.json';
import Hint from 'components/Hint';

type CreateCourseModalProps = {
    onClickCloseButton: () => void;
};

export default function CreateCourseModal({ onClickCloseButton }: CreateCourseModalProps): JSX.Element {
    const { ref } = useKeyboardTrap(onClickCloseButton);

    return (
        <ModalBackground id="modal-background">
            <ModalBody ref={ref} id="modal-body">
                <CreateCourseModalCard id="modal-card">
                    <ModalContent id="modal-content">
                        <HeadlineAndCloseRow>
                            <Headline4>{strings.createCoursePage.title}</Headline4>
                            <TertiaryButton
                                aria-label={strings.createCoursePage.closeButtonAria}
                                onClick={onClickCloseButton}
                            >
                                <IconContainer>
                                    <CloseIcon size={IconSize.SMALL} />
                                </IconContainer>
                            </TertiaryButton>
                        </HeadlineAndCloseRow>
                        <Hint hint={strings.courseEditorPage.steps.titleAndDescription.hint} />
                        <CreateCourseForm />
                    </ModalContent>
                </CreateCourseModalCard>
            </ModalBody>
        </ModalBackground>
    );
}

const ModalBody = styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);

    top: 20%;
    max-width: 750px;

    @media (max-width: ${breakpoints.lg}) {
        width: 100%;
    }

    @media (max-width: ${breakpoints.sm}) {
        top: 17%;
        height: 80vh;
    }
`;

const CreateCourseModalCard = styled(ModalCard)`
    @media (max-width: ${breakpoints.sm}) {
        padding-top: ${sizes.spacingRg};
        padding-bottom: ${sizes.spacingRg};
    }
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    overflow: auto;
`;

const HeadlineAndCloseRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${sizes.spacingSm} ${sizes.spacingSm} 0;
`;

const IconContainer = styled.div`
    padding: ${sizes.spacingSm};
`;
