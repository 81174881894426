import * as React from 'react';
import styled from 'styled-components';
import strings from '../../../strings/strings.json';
import EditableModuleList from './EditableModuleList';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { sizes } from 'theme';
import { WarningNotifierSlim } from 'components/notifiers/WarningNotifierSlim';
import { FormCard } from 'components/forms/FormComponents';
import NavBackButton from 'components/buttons/NavBackButton';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import { ActivityTypeSelectionPageContent } from './ActivityTypeSelectionComponent';
import ActivityUpdateComponent from './ActivityUpdateComponent';
import { ModuleForm } from './ModuleForm';
import { Headline3 } from 'components/Elements';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import { EveryModuleHasActivity } from 'models/Course';

type Component =
    | { name: 'modulesList' }
    | { name: 'addModule' }
    | { name: 'editModule'; moduleId: string }
    | { name: 'addActivity'; moduleId: string }
    | { name: 'editActivity'; moduleId: string; activityId: string };

export function CourseStructureAndContentComponent(): JSX.Element {
    const [component, setComponent] = React.useState<Component>({ name: 'modulesList' });
    const [activeModuleId, setActiveModuleId] = React.useState<string>();

    const context = React.useContext(EditCourseContext);

    React.useEffect(() => {
        if (context.course && context.course.modules.length === 1) {
            setActiveModuleId(context.course.modules[0].id);
        }
    }, [context.course]);

    if (!context.course) return <></>;

    function showEditModule(moduleId: string) {
        setActiveModuleId(moduleId);
        setComponent({ name: 'editModule', moduleId: moduleId });
    }

    function showAddActivity(moduleId: string) {
        setActiveModuleId(moduleId);
        setComponent({ name: 'addActivity', moduleId: moduleId });
    }

    function showEditActivity(moduleId: string, activityId: string) {
        setActiveModuleId(moduleId);
        setComponent({ name: 'editActivity', moduleId: moduleId, activityId: activityId });
    }

    const addModuleButton = (
        <PrimaryButton
            title={strings.courseContentPage.addModuleButton}
            aria={strings.courseContentPage.addModuleButtonAria}
            onClick={() => setComponent({ name: 'addModule' })}
        />
    );

    const everyModuleHasActivity = EveryModuleHasActivity(context.course);

    switch (component.name) {
        case 'modulesList':
            if (context.course.modules.length > 0) {
                return (
                    <CourseContentPageContent>
                        <Headline3>{strings.courseEditorPage.steps.structureAndContent.title}</Headline3>
                        {!everyModuleHasActivity && (
                            <NotificationContainer>
                                <Notification
                                    title={strings.courseEditorPage.incompleteModuleWarning.title}
                                    description={strings.courseEditorPage.incompleteModuleWarning.description}
                                    notificationType={NotificationType.WARNING}
                                    maxWidth="100%"
                                />
                            </NotificationContainer>
                        )}
                        <EditableModuleList
                            courseId={context.course.id}
                            modules={context.course.modules}
                            activeModuleId={activeModuleId}
                            refetchCourse={context.refetchCourse}
                            editModuleClick={showEditModule}
                            addActivityClick={showAddActivity}
                            editActivityClick={showEditActivity}
                        />
                        <AddModuleButtonRow>{addModuleButton}</AddModuleButtonRow>
                    </CourseContentPageContent>
                );
            }
            return (
                <CourseContentPageContent>
                    <Headline3>{strings.courseEditorPage.steps.structureAndContent.title}</Headline3>
                    <NoModulesCard>
                        <WarningNotifierSlim description={strings.courseContentPage.noModules} />
                        {addModuleButton}
                    </NoModulesCard>
                </CourseContentPageContent>
            );
        case 'addModule':
            return (
                <Base setComponent={setComponent}>
                    <ModuleForm
                        mode={'Create'}
                        courseId={context.course.id}
                        closeForm={(moduleId?: string) => {
                            setComponent({ name: 'modulesList' });
                            setActiveModuleId(moduleId ?? '');
                        }}
                    />
                </Base>
            );
        case 'editModule':
            const module = context.course.modules.find((module) => module.id === component.moduleId);

            if (!module) return <></>;

            return (
                <Base setComponent={setComponent}>
                    <ModuleForm
                        mode={'Edit and Delete'}
                        courseId={context.course.id}
                        module={module}
                        closeForm={() => setComponent({ name: 'modulesList' })}
                    />
                </Base>
            );
        case 'addActivity':
            return (
                <Base setComponent={setComponent}>
                    <ActivityTypeSelectionPageContent
                        moduleId={component.moduleId}
                        saveActivityClick={() => setComponent({ name: 'modulesList' })}
                    />
                </Base>
            );
        case 'editActivity':
            return (
                <Base setComponent={setComponent}>
                    <ActivityUpdateComponent
                        activityId={component.activityId}
                        completeUpdateQuestionnaire={() => setComponent({ name: 'modulesList' })}
                    />
                </Base>
            );
    }
}

type BaseProps = {
    children: React.ReactNode;
    setComponent: (value: React.SetStateAction<Component>) => void;
};

const Base: React.FC<BaseProps> = ({ children, setComponent }) => {
    return (
        <>
            <NavBackButton
                backButtonTitle={strings.courseContentPage.backButton.title}
                backButtonAria={strings.courseContentPage.backButton.aria}
                backButtonHref={''}
                buttonFunctionOverride={() => setComponent({ name: 'modulesList' })}
            />
            {children}
        </>
    );
};

const AddModuleButtonRow = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
`;

const NoModulesCard = styled(FormCard).attrs({ as: 'div' })`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${sizes.spacingLg};
    padding: ${sizes.spacingLg} ${sizes.spacingMd};
    width: 100%;
`;

const CourseContentPageContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    align-items: flex-start;
    width: 100%;
`;

const NotificationContainer = styled.div`
    width: 100%;
`;
