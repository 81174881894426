import * as React from 'react';
import strings from '../../../strings/strings.json';
import { useNavigate, useParams } from 'react-router-dom';
import MainSection from '../../../components/sections/MainSection';
import SubpageHeader from 'components/sections/SubpageHeader';
import { courseContentPageUrl as getCourseContentPageUrl } from '../../../apiUrls';
import useGetRequest from 'hooks/useGetRequest';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import { Course } from 'models/Course';
import Skeleton from 'react-loading-skeleton';
import { ModuleForm } from 'pages/edit-course/components/ModuleForm';

export default function EditModulePage(): JSX.Element {
    const params = useParams();
    const navigate = useNavigate();
    const { courseId, moduleId } = params;

    const { data: selectedCourse, loading } = useGetRequest<Course>(`courses/${courseId}`, !courseId);
    const selectedModule = selectedCourse?.modules.filter((module) => module.id === moduleId)[0];

    const backButtonDetails = React.useMemo(() => {
        return {
            title: strings.editTitleModulePage.backButtonLabel,
            aria: strings.editTitleModulePage.backButtonAria,
            href: `/edit-published-course/${courseId}`,
        };
    }, [courseId]);

    // END OF HOOKS: we can now use conditional statements

    if (moduleId && !selectedModule) {
        return (
            <MainSection>
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.editTitleModulePage.errorsFetchingDataMessage}
                />
            </MainSection>
        );
    }

    const courseContentPageUrl = getCourseContentPageUrl(courseId as string);

    function closeForm(moduleId?: string) {
        navigate(courseContentPageUrl, { state: { activeModuleId: moduleId } });
    }

    return (
        <>
            <SubpageHeader title={strings.editTitleModulePage.headerTitle} backButtonDetails={backButtonDetails} />
            <MainSection>
                {loading ? (
                    <Skeleton height={400} />
                ) : (
                    <ModuleForm
                        mode="Edit"
                        module={selectedModule}
                        courseId={courseId as string}
                        closeForm={closeForm}
                    />
                )}
            </MainSection>
        </>
    );
}
