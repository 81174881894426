import styled from 'styled-components';
import { Card, Headline5 } from '../../../components/Elements';
import { breakpoints, sizes, theme } from '../../../theme';
import { LearnerActivity } from '../../../models/LearnerCourse';
import NotificationIcon from '../../../assets/icons/navigation/NotificationIcon';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import strings from '../../../strings/strings.json';
import { IconSize } from '../../../assets/icons/icon-sizes';
import ArrowRightIcon from '../../../assets/icons/controls/ArrowRightIcon';
import { getFriendlyTimeString } from '../../../lib/time-helpers';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function NextLiveTrainingMeetingCard({ nextActivity }: { nextActivity: LearnerActivity }): JSX.Element {
    const navigate = useNavigate();

    const [minutesTilStart, setMinutesTilStart] = React.useState<number>(0);

    const minutesTilEnd = minutesTilStart + (nextActivity.durationInMins ?? 0);

    React.useEffect(() => {
        function calculateMinutesTilStart() {
            const newMinutes = Math.floor(
                (new Date(nextActivity.startDate ?? 0).getTime() - new Date().getTime()) / (1000 * 60),
            );
            setMinutesTilStart(newMinutes);
        }

        const timer = setInterval(() => calculateMinutesTilStart(), 5000);

        return () => clearInterval(timer);
        // eslint-disable-next-line
    }, []);

    return (
        <NextLiveTrainingCardContainer>
            <NotificationIconContainer>
                <NotificationIcon colour={theme.textColourInverted} size={IconSize.LARGE} />
            </NotificationIconContainer>
            <TimeTextLine>
                {minutesTilEnd > 0 ? (
                    <>
                        <Headline5>
                            {nextActivity.title}{' '}
                            {minutesTilStart > 0
                                ? strings.learnerCoursePage.nextLiveTrainingMeeting.willStartInText
                                : strings.learnerCoursePage.nextLiveTrainingMeeting.startedText}
                        </Headline5>
                        <TimeContainer>
                            <Headline5>
                                {minutesTilStart > 0
                                    ? minutesTilStart === 1
                                        ? minutesTilStart.toString() +
                                          '\u00A0' +
                                          strings.learnerCoursePage.nextLiveTrainingMeeting.minutesSingular
                                        : minutesTilStart.toString() +
                                          '\u00A0' +
                                          strings.learnerCoursePage.nextLiveTrainingMeeting.minutes
                                    : getFriendlyTimeString(new Date(nextActivity.startDate ?? 0))}
                                .
                            </Headline5>
                        </TimeContainer>
                    </>
                ) : (
                    <Headline5>
                        {nextActivity.title} {strings.learnerCoursePage.nextLiveTrainingMeeting.hasFinishedText}
                    </Headline5>
                )}
                <PrimaryButton
                    title={strings.learnerCoursePage.nextLiveTrainingMeeting.joinButton.title}
                    aria={strings.learnerCoursePage.nextLiveTrainingMeeting.joinButton.aria}
                    icon={<ArrowRightIcon />}
                    iconPositionRight
                    onClick={() => navigate(`activity/${nextActivity.id}`)}
                />
            </TimeTextLine>
        </NextLiveTrainingCardContainer>
    );
}

const TimeTextLine = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
    flex-wrap: wrap;
`;

const TimeContainer = styled.div`
    background-color: ${theme.cardSecondaryBackgroundColour};
    border-radius: ${sizes.borderRadiusMd};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: ${sizes.spacingMd};
`;

const NotificationIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 70px;
    min-width: 70px;
    background-color: ${theme.primaryBrandColour};
`;

const NextLiveTrainingCardContainer = styled(Card)`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    width: 780px;

    @media (max-width: ${breakpoints.md}) {
        width: 100%;
    }
`;
