import * as React from 'react';
import styled from 'styled-components';
import { ResourcesInput } from './ResourcesForm';
import { ExternalSecondaryLinkButton } from 'components/Elements';
import { breakpoints, sizes, theme } from 'theme';
import strings from 'strings/strings.json';
import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import ExternalLinkIcon from 'assets/icons/navigation/ExternalLinkIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import BinIcon from 'assets/icons/controls/BinIcon';

type ResourceCardProps = {
    resource: ResourcesInput;
    noUniqueId?: boolean;
    deleteResource: (id: string | any, title: string, url: string, deleted: boolean) => void;
    disabled: boolean;
};

export default function ResourceCard({
    resource,
    noUniqueId = false,
    deleteResource,
    disabled,
}: ResourceCardProps): JSX.Element {
    // END OF HOOKS: we can now use conditional statements

    function mouseDownHandler(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        if (noUniqueId) return;
        // this will track 2 ways of opening the link
        // left click (button 0) and middle click (button 1)
        // using a simple onClick would only track left clicks
        if (event.button !== 0 && event.button !== 1) return;
    }

    return (
        <Container key={resource.id}>
            <ResourceWrapper>
                <IconContainer>
                    <ExternalLinkIcon size={IconSize.LARGE} colour={theme.primaryBrandColour} />
                </IconContainer>
                <ExternalSecondaryLinkButton
                    id={`resourceLink-${resource.id}`}
                    href={resource.url}
                    target={'_blank'}
                    aria-label={strings.general.openInNewTab}
                    onMouseDown={(event: any) => mouseDownHandler(event)}
                >
                    {resource.title}
                </ExternalSecondaryLinkButton>
            </ResourceWrapper>
            <SecondaryButton
                title={strings.resourceCard.removeResourcesButton}
                aria={strings.resourceCard.removeResourcesButtonAria}
                disabled={disabled}
                onClick={() => deleteResource(resource.id, resource.title, resource.url, true)}
                icon={<BinIcon size={IconSize.MEDIUM} />}
                alternateColour={SecondaryButtonColour.ALTERNATE}
            />
        </Container>
    );
}

const IconContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
    }
`;

const ResourceWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
`;
