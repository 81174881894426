import strings from '../../../strings/strings.json';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import styled from 'styled-components';
import { sizes, breakpoints } from 'theme';
import Notification, { NotificationType } from 'components/notifiers/Notification';

type CourseEditorButtonsProps = {
    courseId: string;
    courseReadyToPublish: boolean;
};

export function CourseEditorButtons({ courseId, courseReadyToPublish }: CourseEditorButtonsProps): JSX.Element {
    const navigate = useNavigate();

    return (
        <ButtonsContainer id="course-editor-buttons">
            {!courseReadyToPublish && (
                <Notification
                    notificationType={NotificationType.WARNING}
                    title={strings.courseEditorPage.stepsButtonValidation.title}
                    description={strings.courseEditorPage.stepsButtonValidation.description}
                />
            )}
            <SecondaryButton
                onClick={() => {
                    navigate('/trainer/dashboard');
                }}
                title={strings.courseEditorPage.saveButtonTitle}
                aria={strings.courseEditorPage.saveButtonTitleAria}
            />
            <SecondaryButton
                onClick={() => {
                    navigate(`/preview/${courseId}`, {
                        state: { previewContextEntryPoint: `/edit-course/${courseId}` },
                    });
                }}
                title={strings.courseEditorPage.previewCourseButtonTitle}
                aria={strings.courseEditorPage.previewCourseButtonAria}
            />
            <PrimaryButton
                onClick={() => {
                    navigate(`/trainer/course/${courseId}/view`);
                }}
                title={strings.courseEditorPage.reviewAndPublishButtonTitle}
                aria={strings.courseEditorPage.reviewAndPublishButtonTitleAria}
                disabled={!courseReadyToPublish}
            />
        </ButtonsContainer>
    );
}

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    padding-right: ${sizes.spacingMd};

    @media (max-width: ${breakpoints.md}) {
        padding-bottom: ${sizes.spacingXl};
    }

    @media (max-width: ${breakpoints.sm}) {
        padding-right: unset;
    }
`;
