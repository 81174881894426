import styled from 'styled-components';
import { sizes } from '../../theme';
import CheckIcon from 'assets/icons/form-icons/CheckIcon';

export default function LearningObjective({ description }: { description: string }): JSX.Element {
    return (
        <LearningObjectiveContainer>
            <LearningObjectiveIconContainer>
                <CheckIcon />
            </LearningObjectiveIconContainer>
            <p>{description}</p>
        </LearningObjectiveContainer>
    );
}

const LearningObjectiveContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingSm};
`;

const LearningObjectiveIconContainer = styled.div`
    padding: ${sizes.spacingMd};
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: #dcf4ea;
`;
